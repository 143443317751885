<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container text-space-dark-blue"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
    :prevent-click="false"
  >
    <div class="p-14">
      <div class="modal__title">
        <p class="text-4xl text-center geminis">
          {{ title }}
        </p>
      </div>
      <div class="modal__content text-center pb-5">
        <p class="text-lg font-bold pb-5">
          {{ content }}
        </p>
        <img
          :src="imgSrc"
          alt="modal image"
          class="image__middle w-2/3"
        >
      </div>
      <div class="modal__action">
        <PrimaryButton
          :bg-color="'bg-space-green-light'"
          :text="confirmBtn"
          :classes="'text-space-dark-blue h-12 hover:text-space-dark-blue'"
          @click="onClick('confirm', close)"
        />
        <PrimaryButton
          :bg-color="'bg-space-dark-blue'"
          :text="cancelBtn"
          :classes="'text-white h-12 hover:text-white'"
          @click="onClick('cancel', close)"
        />
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import PrimaryButton from "../buttons/PrimaryButton";

export default {
  name: "ReturnToRoom",
  components: {PrimaryButton},
  props: ['title', 'content', 'imgSrc', 'confirmBtn', 'cancelBtn'],
  methods: {
    onClick(input, close) {
      this.$emit(input, this, close);
    }
  }
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #2d3748;
  border-radius: 20px;
  background: white;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
.modal__content {
  max-width: fit-content;
  text-align: center;
}
.image__middle {
  display: initial;
}
.modal__action {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
}
</style>
