<template>
  <HomeLayout :should-navbar-scroll="true" content-classname="px-4 md:px-16">
    <template #content>
      <div
        ref="landing"
        class="relative"
        style="height: 90vh"
      >
        <div class="sm:w-full md:float-right md:w-8/12 xl:w-1/2 2xl:w-3/5 h-full flex items-center z-10">
          <div class="w-full md:mb-48 float-right text-center">
            <h1 class="geminis text-7xl lg:text-8xl 2xl:text-9xl text-shadow mb-14">
              {{ $t('common.welcome.first') }}<br>{{ $t('common.welcome.second') }}!
            </h1>
            <div class="hidden md:flex flex-col md:flex-row space-y-4 md:space-x-2 md:space-y-0 m-auto xl:w-5/6">
              <div class="md:w-1/2">
                <SpaceButton
                  :text="$t('homeScreen.playOnline').toString()"
                  link="room.link"
                />
              </div>
              <div class="md:w-1/2">
                <SpaceButton
                  :text="$t('homeScreen.createPrivateRoom').toString()"
                  @click.native="createRoom"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="hidden md:block bg-astronaute mx-16 md:mx-20 w-9/12 h-full !bg-no-repeat bg-contain" />
      </div>
      <div class="px-7 md:px-16 space-y-24 w-full">
        <div class="flex items-center">
          <div class="md:w-3/5 z-10 md:js-scroll md:slide-left">
            <h2 class="geminis text-4xl mb-5">
              {{ $t('homeScreen.contentBlocks.1.title') }}
            </h2>
            <h3 class="text-2xl font-bold mb-5">
              {{ $t('homeScreen.contentBlocks.1.subtitle') }}
            </h3>
            <p class="mb-5 font-light xl:text-lg">
              {{ $t('homeScreen.contentBlocks.1.text1') }}
            </p>
            <p class="font-light xl:text-lg">
              {{ $t('homeScreen.contentBlocks.1.text2') }}
            </p>
          </div>
          <div class="hidden md:block w-3/6 lg:w-2/5 h-full relative left-10">
            <div class="absolute relative -top-20 2xl:w-10/12">
              <img
                :data-src="require('@/assets/images/planet-red.svg')"
                alt="planet-red"
                width="100%"
                height="100%"
                class="planet-red-img min-h-32 right-0"
              >
              <img
                :data-src="require('@/assets/images/diamond-4.webp')"
                alt="diamonds"
                class="diamond-img absolute w-20 lg:w-36 left-2 top-2 lg:left-14 lg:left-0 lg:right-72"
              >
              <img
                :data-src="require('@/assets/images/diamond-3.svg')"
                alt="diamonds"
                class="diamond-img-2 absolute w-20 -bottom-2 right-1/4 lg:w-32 "
              >
            </div>
          </div>
        </div>

        <div class="flex flex-row-reverse items-center md:justify-end">
          <div class="md:w-3/5 md:text-right z-10 md:js-scroll md:slide-right">
            <h2 class="geminis text-4xl mb-5">
              {{ $t('homeScreen.contentBlocks.2.title') }}
            </h2>
            <h3 class="text-2xl font-bold mb-5">
              {{ $t('homeScreen.contentBlocks.2.subtitle') }}
            </h3>
            <p class="mb-5 font-light xl:text-lg">
              {{ $t('homeScreen.contentBlocks.2.text1') }}
            </p>
            <p class="font-light xl:text-lg">
              {{ $t('homeScreen.contentBlocks.2.text2') }}
            </p>
          </div>
          <div class="hidden md:block w-3/6 lg:w-2/5 h-full relative -left-10 lg:-left-0">
            <div class="absolute relative 2xl:w-10/12 -top-20">
              <img
                :data-src="require('@/assets/images/planet-earth.svg')"
                alt="planet-earth"
                width="100%"
                height="100%"
                class="planet-earth-img min-h-32"
              >
              <img
                :data-src="require('@/assets/images/diamond-2.svg')"
                alt="diamonds"
                class="diamond-img-earth absolute w-28 lg:w-36 bottom-2/3 -right-6 min-h-32"
              >
              <img
                :data-src="require('@/assets/images/diamond-4.webp')"
                alt="diamonds"
                class="diamond-img-earth-2 absolute w-24 lg:w-32 top-2/4 -right-6 min-h-32"
              >
            </div>
          </div>
        </div>

        <div class="flex items-center">
          <div class="md:w-3/5 z-10 md:js-scroll md:slide-left">
            <h2 class="geminis text-4xl mb-5">
              {{ $t('homeScreen.contentBlocks.3.title') }}
            </h2>
            <h3 class="text-2xl font-bold mb-5">
              {{ $t('homeScreen.contentBlocks.3.subtitle') }}
            </h3>
            <p class="mb-5 font-light">
              {{ $t('homeScreen.contentBlocks.3.text1') }}
            </p>
            <p class="mb-5 font-light">
              <strong class="font-bold">{{ $t('homeScreen.contentBlocks.3.text2.title') }}</strong> <br>
              {{ $t('homeScreen.contentBlocks.3.text2.text') }}
            </p>
            <p class="font-light">
              <strong class="font-bold">{{ $t('homeScreen.contentBlocks.3.text3.title') }}</strong> <br>
              {{ $t('homeScreen.contentBlocks.3.text3.text') }}
            </p>
          </div>
          <div class="hidden md:block w-3/5 lg:w-2/5 h-full relative -top-20">
            <div class="relative 2xl:w-10/12">
              <img
                :data-src="require('@/assets/images/planet-egg.svg')"
                alt="planet-egg"
                width="100%"
                height="100%"
                class="planet-egg-img min-h-32"
              >
              <img
                :data-src="require('@/assets/images/diamond-4.webp')"
                alt="diamonds"
                class="diamond-img-egg absolute w-24 lg:w-32 bottom-2/3 -right-20 min-h-32"
              >
              <img
                :data-src="require('@/assets/images/diamond-3.svg')"
                alt="diamonds"
                width="100%"
                height="100%"
                class="diamond-img-egg-2 absolute w-20 lg:w-28 top-1/3 -right-20"
              >
            </div>
          </div>
        </div>
      </div>

      <div class="w-full md:px-10">
        <div class="relative w-full h-46 sm:h-52 md:h-60 bg-white rounded-lg md:rounded-full mt-24">
          <div class="flex flex-col w-full h-full items-center pb-6 text-center">
            <h3 class="geminis text-space-dark-blue mt-10 text-2xl md:text-4xl lg:text-5xl px-2">
              {{ $t('homeScreen.joinAdventure.title') }}
            </h3>
            <h4 class="geminis text-space-dark-blue mt-4 text-xl md:text-3xl lg:text-4xl">
              {{ $t('homeScreen.joinAdventure.subtitle') }}
            </h4>
            <SpaceButton
              :text="$t('homeScreen.joinAdventure.createAccount')"
              class="hidden md:block w-1/3 mt-6 xs:mb-0"
              :link="'register'"
            />
          </div>
          <img
            :data-src="require('@/assets/images/splash.svg')"
            alt="Splash"
            width="100%"
            height="100%"
            class="hidden lg:block absolute w-1/6 -left-6 -top-4"
          >
          <img
            :data-src="require('@/assets/images/diamond.svg')"
            alt="Diamond"
            width="100%"
            height="100%"
            class="hidden lg:block diamond absolute w-1/5 right-0 top-28"
          >
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex flex-col items-center justify-center -mt-1 sm:-mt-3">
        <img
          :data-src="require('@/assets/images/dotcode.png')"
          alt="Dotcode"
          width="100%"
          height="100%"
          class="z-10 w-1/2 md:w-auto"
        >
        <div class="geminis">
          Made with love by Dotcode
        </div>
        <div class="geminis">
          2022 - 2023, Dotcode
        </div>
      </div>
    </template>
  </HomeLayout>
</template>

<script>
import HomeLayout from "@/layouts/HomeLayout";
import SpaceButton from "@/components/buttons/SpaceButton.vue";
import {ROUTE_ROOM_CONNECTION} from "../router";
import {isNull} from "../utils/isNull";
import ReturnToRoom from "../components/modals/ReturnToRoom";
import {API} from "@/utils/api";
import {ROOM_STATUS_ON} from "../constants/rooms/RoomStatus";
import roomConfigurationService from "../services/roomConfigurationService";
import RoomConfigurationService from "../services/roomConfigurationService";
import {GAME_OVER} from "../constants/games/GameStatusConstants";

export default {
  name: "Home",
  components: {
    HomeLayout,
    SpaceButton
  },
  mounted() {
    this.appearOnScroll();
    this.isUserConnectedToARoom();
    this.resyncSocket();
  },
  methods: {
    async createRoom() {
      await RoomConfigurationService.createRoom();
    },
    appearOnScroll() {
      // Scroll appear
      const scrollElements = document.querySelectorAll(".js-scroll");

      const elementInView = (el, dividend = 1) => {
        const elementTop = el.getBoundingClientRect().top;
        return (
          elementTop <=
					(window.innerHeight || document.documentElement.clientHeight) / dividend
        );
      };

      const elementOutofView = (el) => {
        const elementTop = el.getBoundingClientRect().top;
        return (
          elementTop > (window.innerHeight || document.documentElement.clientHeight)
        );
      };

      const displayScrollElement = (element) => {
        element.classList.add("scrolled");
      };

      const hideScrollElement = (element) => {
        element.classList.remove("scrolled");
      };

      const handleScrollAnimation = () => {
        scrollElements.forEach((el) => {
          if (elementInView(el, 1.15)) {
            displayScrollElement(el);
          } else if (elementOutofView(el)) {
            hideScrollElement(el);
          }
        });
      };

      window.addEventListener("scroll", () => {
        handleScrollAnimation();
      });
    },

    async isUserConnectedToARoom() {
      const user = this.$store.getters.getUser;
      if (isNull(user.room_id)) {
        return;
      }
      if (user.room_state === GAME_OVER) {
        await roomConfigurationService.disconnectRoom(this.$socket.client, user, room.pin);
      }

      const remoteUser = (await API.get(`user/${user.id}/me`)).data;
      if (isNull(remoteUser) || isNull(remoteUser.room)) {
        return;
      }

      const room = (await API.get('room', {params: {id: remoteUser.room.id, status: ROOM_STATUS_ON}})).data;

      if (isNull(room)) {
        return;
      }
      await this.$vfm.show({
        component: ReturnToRoom,
        bind: {
          title: this.$i18n.t('modale.in_progress_game.title'),
          content: this.$i18n.t('modale.in_progress_game.content'),
          imgSrc: require(`@/assets/images/modals/computer_astronaut.svg`),
          confirmBtn: this.$i18n.t('modale.in_progress_game.confirm_button'),
          cancelBtn: this.$i18n.t('modale.in_progress_game.cancel_button'),
        },
        on: {
          confirm(that, close) {
            close();
            that.$router.push({
              name: ROUTE_ROOM_CONNECTION.name,
              query: {pin: room.pin}
            }).catch(() => {});
          },
          cancel(that, close) {
            close();
            roomConfigurationService.disconnectRoom(that.$socket.client, remoteUser, room.pin);
          }
        },
      });
    },

    async resyncSocket() {
      const user = this.$store.getters.getUser;
      if (isNull(user.id)) {
        return;
      }

      roomConfigurationService.resyncSocket(this.$socket.client, user);
    }
  }
};
</script>

<style scoped>

body {
  @apply overflow-x-hidden;
}
.bg-astronaute {
  background: url("~@/assets/images/welcome-on-board.svg");
}
.line-height-p {
  line-height: 1.8;
}
.planet-red-img {
  animation-name: planet-movement;
  animation-duration: 120s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
}
.diamond-img {
  animation-name: diamond-movement;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.diamond-img-2 {
  animation-name: diamond-movement;
  animation-duration: 5s;
  animation-delay: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.planet-earth-img {
  animation-name: planet-movement;
  animation-duration: 120s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
}
.diamond-img-earth {
  transform: rotate(180deg);
  animation-name: diamond-movement;
  animation-duration: 5s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.diamond-img-earth-2 {
  transform: rotate(180deg);
  animation-name: diamond-movement;
  animation-duration: 5s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.planet-egg-img {
  animation-name: planet-movement;
  animation-duration: 120s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: normal;
}
.diamond-img-egg {
  animation-name: diamond-movement;
  animation-duration: 5s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.diamond-img-egg-2 {
  animation-name: diamond-movement;
  animation-duration: 5s;
  animation-delay: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.diamond {
  animation-name: diamond-movement;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes planet-movement {
  0% {
    transform: rotate(0) translate(0, 0);
  }
  50% {
    transform: rotate(180deg) translate(50px, -40px);
  }
  100% {
    transform: rotate(360deg) translate(0, 0);
  }
}
@keyframes diamond-movement {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}
/* Scroll appear */
.js-scroll {
  opacity: 0;
  transition: opacity 500ms;
}
.js-scroll.scrolled {
  opacity: 1;
}
.scrolled.slide-left {
  animation: slide-in-left 0.75s ease-in-out both;
}
.scrolled.slide-right {
  animation: slide-in-right 0.75s ease-in-out both;
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

</style>
